import React from "react"
import "./App.scss"
import ReactAudioPlayer from "react-audio-player"

import Draggable from "react-draggable"

import { ReactComponent as PinkD } from "./assets/dolphin-pink.svg"
import { ReactComponent as BlueD } from "./assets/dolphin-blue.svg"

// import { ReactComponent as Soundcloud } from "./assets/soundcloud.svg"
// import { ReactComponent as Insta } from "./assets/instagram.svg"

import { ReactComponent as Alcohol } from "./assets/alcohol.svg"
import { ReactComponent as Beach } from "./assets/beach.svg"
import { ReactComponent as Beer } from "./assets/beer.svg"
import { ReactComponent as Palm } from "./assets/palm.svg"
import { ReactComponent as Cocktail } from "./assets/cocktail.svg"
import { ReactComponent as Bottle } from "./assets/bottle.svg"
import { ReactComponent as Flips } from "./assets/flip-flops.svg"

const audioFiles = {
  oka: {
    post:
      "https://firebasestorage.googleapis.com/v0/b/pinkbeton-landing-pages.appspot.com/o/dolphins%2Fpublic%2Fpost_small.mp3?alt=media&token=901a6552-b02f-4a85-ac18-034d66e683a1",
    pre:
      "https://firebasestorage.googleapis.com/v0/b/pinkbeton-landing-pages.appspot.com/o/dolphins%2Fpublic%2Fpre_small.mp3?alt=media&token=0c28490a-7a1c-40f8-8e62-4eb8f7c081a9",
    main:
      "https://firebasestorage.googleapis.com/v0/b/pinkbeton-landing-pages.appspot.com/o/dolphins%2Fpublic%2Fmain_small.mp3?alt=media&token=ed40e168-3c9f-4189-b949-b26c3a84ee2f",
  },
  kawa: {
    main:
      "https://firebasestorage.googleapis.com/v0/b/pinkbeton-landing-pages.appspot.com/o/dolphins%2Fpublic%2Fkawa.mp3?alt=media&token=2727aadf-25c5-4a9b-9d14-2503e8477ce2",
  },
}

function Player({ src, title }) {
  return (
    <div className="component-player">
      <div className="component-player--title">{title}</div>
      <ReactAudioPlayer src={src} controls />
    </div>
  )
}

function App() {
  return (
    <div className="dolphin-app">
      <div className="body">
        <Draggable>
          <a
            draggable="false"
            href="https://soundcloud.com/dolphinboyfriends"
            target="_blank"
            rel="noopener noreferrer"
          >
            soundcloud
          </a>
        </Draggable>

        <Player
          title="@KAWA - performance"
          src={audioFiles.kawa.main}
          controls
        />

        <Draggable>
          <Bottle />
        </Draggable>

        <Draggable>
          <a
            draggable="false"
            href="https://www.instagram.com/thedolphinboyfriends/"
            target="_blank"
            rel="noopener noreferrer"
          >
            instagram
          </a>
        </Draggable>

        <Player
          title="@OKA - performance - pre"
          src={audioFiles.oka.pre}
          controls
        />
        <Player title="@OKA - performance" src={audioFiles.oka.main} controls />
        <Player
          title="@OKA - performance - post"
          src={audioFiles.oka.post}
          controls
        />

        <Draggable>
          <Flips />
        </Draggable>

        <Draggable>
          <BlueD />
        </Draggable>

        <Draggable>
          <Cocktail />
        </Draggable>

        <Draggable>
          <Beach />
        </Draggable>

        <Draggable>
          <Beer />
        </Draggable>

        <Draggable>
          <Alcohol />
        </Draggable>

        <Draggable>
          <Palm />
        </Draggable>

        <Draggable>
          <h1>Dolphin Boyfriends</h1>
        </Draggable>

        <Draggable>
          <Cocktail />
        </Draggable>

        <Draggable>
          <PinkD />
        </Draggable>
      </div>
      <footer>
        booking over pinkmetalpetal@hotmail.com
        <br />
      </footer>
    </div>
  )
}

export default App
